import React, { Component } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Strings from "../assets/strings"

export default class Home extends Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {}

  render() {
    return (
      <Layout>
        <SEO title={Strings.landingPage.meta.title} />
        <p>BRIVE Services</p>
      </Layout>
    )
  }
}
